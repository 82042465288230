<template>
  <div>
    <MyHeader />
    <main id="content">
      <nav class="user-tab-nav">
        <div class="container">
          <ProfileMenu />
        </div>
      </nav>
      <div class="container mt-4 mt-lg-5">
        <BookingInfo />
        <div class="row mb-4 mb-lg-5">
          <div class="col-12">
            <UserTripMenu />
          </div>
        </div>

        <h3 class="min-title mb-4 mb-lg-5">
          {{ travelers.length }} Travelers on this trip
        </h3>
        <div class="row mb-5">
          <div class="col-12 col-lg-6 mb-4">
            <ul class="travelers-list">
              <li v-for="(traveler, index) in travelers"
                  :key="index">
                <div class="thumb">
                  <img src="/img/user-thumb.jpg">
                </div>
                <h5>{{ traveler.fullname }}</h5>
              </li>
            </ul>
            <hr>
            <a href="javascript:;"
               v-if="booking && booking.status !== 3 && travelers.length < booking.travelers"
               id="addTravelerBtn"
               :class="{ btn: true, 'd-none': travelersFlag }"
               @click="travelersFlag = true"><i class="icon-plus" /> Add more Travelers</a>
            <form :class="{ addTraveler: true, 'd-block': travelersFlag }"
                  @submit.prevent="addTraveler">
              <a href="javascript:;"
                 @click="travelersFlag = false"
                 class="btn mb-2"><i class="icon-close" /> Cancel</a>
              <div class="form-group has-icon">
                <i class="icon-user" />
                <input v-model="travelerName"
                       v-validate="'required'"
                       type="text"
                       class="form-control"
                       name="name"
                       placeholder="Full Name">
              </div>
              <span class="text-danger small mb-3 d-block fix-margin"
                    v-if="errors.has('name')">{{ errors.first('name') }}</span>
              <div class="form-group has-icon">
                <i class="icon-envelop" />
                <input v-model="travelerMail"
                       v-validate="'required|email'"
                       type="email"
                       class="form-control"
                       name="email"
                       placeholder="Email">
              </div>
              <span class="text-danger small mb-3 d-block fix-margin"
                    v-if="errors.has('email')">{{ errors.first('email') }}</span>
              <button class="btn btn-primary">
                <i class="icon-check" /> CONFIRM
              </button>
            </form>
          </div>
        </div>
      </div>
    </main>
    <MyFooter />
  </div>
</template>

<script lang="ts">
import ProfileMenu from '../../components/users/ProfileMenu.vue';
import UserTripMenu from '../../components/users/UserTripMenu.vue';
import BookingInfo from '../../components/users/BookingInfo.vue';

export default {
  name: 'UserTripTravelers',
  components: {
    ProfileMenu,
    UserTripMenu,
    BookingInfo
  },
  data() {
    return {
      travelersArray: {},
      travelerName: '',
      travelerMail: '',
      travelersFlag: false,
      // booking: {},
    };
  },
  computed: {
    booking() {
      return this.$store.getters.getBookingTrip;
    },
    travelers() {
      return this.travelersArray;
    }
  },
  created() {
    setTimeout(() => {
      const { status } = this.$store.getters.getBookingTrip;
      if (status === 2 || status === 5) {
        this.$router.push({ name: 'user-trip-overview', params: { id: this.$route.params.id } });
      } else if (!this.$route.params.id) {
        this.$router.push('/');
      }
    }, 500);
    this.getTravelers();
    // this.booking = JSON.parse(this.$localStorage.get('bookingTrip'));
  },
  methods: {
    getTravelers() {
      this.$axios.get(`/v1/bookings/${this.$route.params.id}/guests`).then((response) => {
        this.travelersArray = response.data.data;
      });
    },
    addTraveler() {
      this.$validator.validate().then((result) => {
        if (result) {
          const formData = new FormData();
          formData.append('fullname', this.travelerName);
          formData.append('email', this.travelerMail);
          this.$axios.post(`/v1/bookings/${this.$route.params.id}/guests`, formData).then(() => {
            this.travelerName = '';
            this.travelerMail = '';
            this.$validator.reset();
            this.getTravelers();
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
