<template>
  <div v-if="getUser">
    <nav class="user-tab-nav">
      <div class="container">
        <ul>
          <li>
            <router-link :to="{ name: 'user-trips' }"
                         :class="{ active: this.$route.name === 'user-trip-overview' || this.$route.name === 'user-trip-travelers' || this.$route.name === 'user-trip-itinerary' || this.$route.name === 'user-trip-groceries' || this.$route.name === 'user-trip-charges' }">
              My Trips
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'user-wishlist' }">
              Wishlist
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'user-messages' }"
                         :class="{ active: this.$route.name === 'user-message' }">
              Messages
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'user-profile' }"
                         :class="{ active: this.$route.name === 'user-edit-profile' }">
              Profile & Account
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';

export default {
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters([
      'getUser',
    ]),
  },
};
</script>

<style lang="scss" scoped>

</style>
