<template>
  <div>
    <ul class="myTrip-nav">
      <li>
        <router-link :to="{ name: 'user-trip-overview', params: { id: this.$route.params.id } }">
          <i class="icon-checklist" /> Overview
        </router-link>
      </li>
      <li v-if="status !== 2 && status !== 5">
        <router-link :to="{ name: 'user-trip-travelers', params: { id: this.$route.params.id } }">
          <i class="icon-users" /> Travelers
        </router-link>
      </li>
      <li v-if="status !== 2 && status !== 5">
        <router-link :to="{ name: 'user-trip-itinerary', params: { id: this.$route.params.id } }">
          <i class="icon-calendar" /> Itinerary, Experiences & Activities
        </router-link>
      </li>
      <li v-if="status !== 2 && status !== 5">
        <router-link :to="{ name: 'user-trip-groceries', params: { id: this.$route.params.id } }">
          <i class="icon-groceries" /> Groceries
        </router-link>
      </li>
      <li v-if="status !== 2 && status !== 5">
        <router-link :to="{ name: 'user-trip-charges', params: { id: this.$route.params.id } }">
          <i class="icon-papers" /> Charges
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: Number,
      default: null
    },
  },
  created() {
  },
};
</script>

<style scoped>
    .disableLink {
        pointer-events: none!important;
    }
</style>
