<template>
  <div class="trip-detail-head"
       v-if="booking && booking.user && booking.concierge">
    <div class="d-flex border-bottom flex-wrap pt-3 align-items-center justify-content-between">
      <div class="nameTrip pb-3 col-12 col-md-auto">
        <h1>{{ booking.property.name }}</h1>
        <p class="locate">
          {{ booking.property.state.name }}, {{ booking.property.country.name }}
        </p>
      </div>
      <div class="col-12 pb-3 col-md-auto ml-auto">
        <div class="concierge">
          <div class="pic">
            <img :src="booking.booking && booking.booking.concierge.avatar ? booking.booking.concierge.avatar.path : '/img/user-thumb.jpg'">
          </div>
          <div class="name">
            <h4>{{ booking.concierge.name }} {{ booking.concierge.surname }}</h4>
            <p>Your Concierge</p>
          </div>
        </div>
      </div>
      <div class="col-12 pb-3 col-md-auto">
        <router-link class="btn btn-block btn-primary btn-sm"
                     :to="{ name: 'user-message', params: { id: booking.id }}">
          <strong>CONTACT CONCIERGE</strong>
        </router-link>
      </div>
    </div>
    <div class="d-flex flex-wrap pt-3 align-items-center">
      <div class="col-12 col-md-auto mb-3">
        <label class="calenBook">
          <i class="icon-calendar" />
          <div><small>Check In</small> {{ booking.check_in|beatyDate }}</div>
          <i class="icon-arrow-right" />
          <div><small>Check Out</small> {{ booking.check_out|beatyDate }}</div>
        </label>
      </div>
      <div class="col-12 col-md-auto mb-3 border-lg">
        <p class="ginf">
          Booked by <strong>{{ booking.user.name }} {{ booking.user.surname }}</strong><br> {{ booking.created_at|beatyDate }}
        </p>
      </div>
      <div class="col-12 col-md-auto mb-3"
           v-if="booking.confirmation_number">
        <p class="ginf">
          Confirmation number: <br><strong><span>{{ booking.confirmation_number }}</span></strong>
        </p>
      </div>
      <div class="col-12 col-md-auto pb-3 ml-auto">
        <router-link v-if="booking && booking.status === 1"
                     :to="{ name: 'edit-or-cancel-trip', params: { id: this.$route.params.id } }"
                     class="btn btn-block btn-sm">
          <i class="icon-edit" />  Modify or Cancel Trip
        </router-link>
        <router-link v-if="booking && booking.status === 2 || booking.status === 5"
                     :to="{ name: 'user-trip-cancellation', params: { id: this.$route.params.id } }"
                     class="btn btn-block btn-sm">
          <i class="icon-edit" />  Cancel Trip
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapActions } from 'vuex';

export default {
  components: {
  },
  data() {
    return {
    };
  },
  methods: {
    ...mapActions([
      'requestBookingTrip',
    ]),
  },
  computed: {
    booking() {
      return this.$store.getters.getBookingTrip;
    }
  },
  created() {
    if (this.$route.params.id) {
      this.requestBookingTrip(this.$route.params.id);
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
